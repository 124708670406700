

body {
    background-color: black !important;
}


.aboutText h2 {
    color: #2D9EC1 !important;
    font-family: 'Arial';
    font-style: normal;

}

.aboutText h6 {
    font-family: 'Hind';
    font-style: normal;
    
}
.round-btn{
    width:150px;
    padding: 10px;
    background: transparent;
    border: 2px solid white;
    border-radius: 100px;
    color: white;
    font-weight: bold;
    transition-duration: 500ms;
    
    }
    
    .round-btn:hover{
        background-color: white ;
        color: black;
        transition-duration: 500ms;
    }

