.main-bg{
    background-color: #0D0E1E;
}
.head{
    background-image: url("../resources/homeHeader.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    min-height: 90vh;
    max-height: auto;
   
}

.head-dark-bg{
    min-height: 90vh;
    max-height: auto;
    background-color: rgba(13, 14, 30, 0.8);
    
    margin: 0;
    overflow: hidden;
}

.link{
    text-decoration: none;
    
}

.nav-link{
   cursor: pointer; 
}

.nav-link:hover{
    cursor: pointer; 
    color: rgb(145, 21, 203);
    font-size: 22px;
    transition-duration: 500ms;
 }

.Home-main-header{
    /* font-size: 5vw; */
    color: white;
    font-weight: bold;
    font-family: Hind;
    
}

.Home-main-para{
    color: rgb(202, 212, 250);
}

.round-btn{
width:150px;
padding: 10px;
background: transparent;
border: 2px solid white;
border-radius: 100px;
color: white;
font-weight: bold;
transition-duration: 500ms;

}

.round-btn:hover{
    background-color: white ;
    color: black;
    transition-duration: 500ms;
}

.round-btn2{
    width:150px;
    padding: 10px;
    background: transparent;
    border: 2px solid black;
    border-radius: 100px;
    color: black;
    font-weight: bold;
    transition-duration: 500ms;
    
    }
    
    .round-btn2:hover{
        background-color: black ;
        color: white;
        transition-duration: 500ms;
    }

a{
    text-decoration: none;
}

.taskBar{
    background-color: #2D9EC1;
    min-height: 10vh;
    height: auto;
}
 .homePara{
    color: rgb(197, 195, 195);
    font-family: Arial;
 }

 .taskbarText{
    line-height: 0px;
 }

 .blueWork-bg{
    background-color: #343883;
    
 }

 .whiteWork-bg{
    background-color: #E2E5F1;
    
 }

 .workTitle{
    color: #2D9EC1;
    letter-spacing: 3px;
    
 }

 .text-aqua{
    color: #2D9EC1;
 }

 .testimonial-bg{
  
    background-image: url("../resources/testimonial.jpg");
    background-size: cover;
    background-position: center;
    height: auto;
    
 }

 .dark-bg{
    height: auto;
    background-color:  #0D0E1E;
    opacity: 85%;
    margin: 0;
    overflow: hidden;
}

.blue-service{
    background-color: #343883;
}

.red-service{
    background-color: #DA2E47;
}

.dark-service{
    background-color: #343655;
}

.social:hover{
    color: rgb(60, 197, 243);
    transition-duration: 300ms;
}

.social{
    color: white;
    transition-duration: 300ms;

}

.footerLink{
    color: white;
    text-decoration: none;
}

.footerLink:hover{
    color: rgb(41, 214, 224);
    transition-duration: 300ms;
    
}

.works-bg{
    background-image: url("../resources/workbg.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;

    height: 70vh;
}

.dark-bg{
    min-height: 70vh;
    max-height: auto;
   
    background-color: rgba(13, 14, 30, 0.8);

    margin: 0;
    overflow: hidden;
}
.workNav:hover{
    background-color: #32b1d7;
    transition-duration: 300ms;
    cursor: pointer;
}

.darkWork{
    background-color: #2D9EC1;
}

.darkWork:hover{
    background-color: #32bae3;
    transition-duration: 300ms;
    cursor: pointer;


}
.nav{
    display: flex;
    flex-direction: row;
}

.red-Round-btn{
    padding: 12px;
    color: white;
    font-weight: bold;
    background-color: #ce3737;
border-radius: 100px;
padding-inline: 25px;
}

.red-Round-btn:hover{
    background-color: #b62f2f;
    transition-duration: 300ms;
}


.contact-bg{
    background-image: url("../resources/contactus.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    height: 70vh;
}

.services-bg{
    background-image: url("../resources/aboutImg.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    height: 70vh;
}

.technology{
    background-color: rgb(255, 255, 255);
}